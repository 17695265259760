// src/modules/CompanyPage/CompanyPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CompanyPage.css';
import API_BASE_URL from '../../constants/apiConfig';
import EditButton from '../shared/EditButton';

const CompanyPage = () => {
  const [logoUrl, setLogoUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [editedDetails, setEditedDetails] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [logoToRemove, setLogoToRemove] = useState(false);
  
  // Get the customer ID from Redux state
  const customerId = useSelector((state) => state.user.user?.customer_id);

  const fetchLogo = useCallback(async () => {
    if (!customerId) return;
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/logo/fetch?customer_id=${customerId}`, {
        method: 'GET',
      });
      if (response.ok) {
        const blob = await response.blob();
        setLogoUrl(URL.createObjectURL(blob));
      } else if (response.status === 404) {
        setLogoUrl(null);
      } else {
        throw new Error('Failed to fetch logo');
      }
    } catch (error) {
      setError('Error fetching logo: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  }, [customerId]);

  const fetchCompanyDetails = useCallback(async () => {
    if (!customerId) return;
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/company/details?customer_id=${customerId}`, {
        method: 'GET',
      });
      if (response.ok) {
        const data = await response.json();
        setCompanyDetails(data);
        setEditedDetails(data);
      } else {
        throw new Error('Failed to fetch company details');
      }
    } catch (error) {
      setError('Error fetching company details: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  }, [customerId]);

  useEffect(() => {
    if (customerId) {
      fetchLogo();
      fetchCompanyDetails();
    }
  }, [customerId, fetchLogo, fetchCompanyDetails]);

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogoFile(file);
      setLogoUrl(URL.createObjectURL(file));
      setLogoToRemove(false);
    }
  };

  const handleRemove = () => {
    setLogoToRemove(true);
    setLogoUrl(null);
    setLogoFile(null);
  };

  const handleEdit = () => {
    setIsEditable(true);
  };

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Update company details
      const detailsResponse = await fetch(`${API_BASE_URL}/company/update?customer_id=${customerId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedDetails),
      });
      if (!detailsResponse.ok) throw new Error('Failed to update company details');

      // Handle logo changes
      if (logoFile) {
        const formData = new FormData();
        formData.append('logo', logoFile);
        const logoResponse = await fetch(`${API_BASE_URL}/logo/upload?customer_id=${customerId}`, {
          method: 'POST',
          body: formData,
        });
        if (!logoResponse.ok) throw new Error('Failed to upload logo');
      } else if (logoToRemove) {
        const removeResponse = await fetch(`${API_BASE_URL}/logo/remove?customer_id=${customerId}`, {
          method: 'DELETE',
        });
        if (!removeResponse.ok) throw new Error('Failed to remove logo');
      }

      setCompanyDetails(editedDetails);
      setIsEditable(false);
      setLogoFile(null);
      setLogoToRemove(false);
      fetchLogo(); // Refresh logo after all changes
    } catch (error) {
      setError('Error updating company information: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setEditedDetails(companyDetails);
    setIsEditable(false);
    setLogoFile(null);
    setLogoToRemove(false);
    fetchLogo(); // Revert logo changes
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails(prev => ({ ...prev, [name]: value }));
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return ['(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber;
  };

  const formatAddress = (details) => {
    return `${details.street_address}, ${details.city}, ${details.state} ${details.zip_code}`;
  };

  if (!customerId) {
    return <div className="alert alert-warning">User information not available.</div>;
  }

  return (
    <div className="coa-company-page-root">
      <div className="card shadow">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h1 className="card-title mb-0">Company Settings</h1>
          <EditButton 
            isEditable={isEditable}
            onEdit={handleEdit}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        </div>
        <div className="card-body">
          <h5>Company Logo</h5>
          <div className="company-logo mb-3">
            {isLoading ? (
              <p>Loading...</p>
            ) : logoUrl ? (
              <div>
                <img 
                  src={logoUrl} 
                  alt="Company Logo" 
                  className="img-thumbnail" 
                />
              </div>
            ) : (
              <div>
                <p>No logo uploaded</p>
              </div>
            )}
          </div>
          
          {isEditable && (
            <div className="logo-actions">
              {logoUrl ? (
                <button 
                  onClick={handleRemove} 
                  className="btn btn-danger"
                  disabled={isLoading}
                >
                  Remove Logo
                </button>
              ) : (
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleUpload}
                  disabled={isLoading}
                  className="form-control-file"
                />
              )}
            </div>
          )}
          
          <div className="details-section">
            <h5 className="mt-4">Company Details</h5>
            {isLoading ? (
              <p>Loading...</p>
            ) : companyDetails ? (
              <div>
                {isEditable ? (
                  <div className="form-grid">
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">Name:</label>
                      <input type="text" className="form-control" id="name" name="name" value={editedDetails.name} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="street_address" className="form-label">Address:</label>
                      <input type="text" className="form-control" id="street_address" name="street_address" value={editedDetails.street_address} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="city" className="form-label">City:</label>
                      <input type="text" className="form-control" id="city" name="city" value={editedDetails.city} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="state" className="form-label">State:</label>
                      <input type="text" className="form-control" id="state" name="state" value={editedDetails.state} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="zip_code" className="form-label">Zip Code:</label>
                      <input type="text" className="form-control" id="zip_code" name="zip_code" value={editedDetails.zip_code} onChange={handleInputChange} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="office_phone_number" className="form-label">Office Phone:</label>
                      <input 
                        type="text" 
                        className="form-control" 
                        id="office_phone_number" 
                        name="office_phone_number" 
                        value={formatPhoneNumber(editedDetails.office_phone_number)} 
                        onChange={handleInputChange} 
                      />
                    </div>
                  </div>
                ) : (
                  <div className="read-only-details">
                    <p><strong>Name:</strong> {companyDetails.name}</p>
                    <p><strong>Address:</strong> {formatAddress(companyDetails)}</p>
                    <p><strong>Office Phone:</strong> {formatPhoneNumber(companyDetails.office_phone_number)}</p>
                  </div>
                )}
              </div>
            ) : (
              <p>No company details available</p>
            )}
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
      </div>
    </div>
  );
};

export default CompanyPage;