import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'bootstrap';
import './UsersPage.css';

const formatPhoneNumber = (value) => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumber.startsWith('1')) {
    if (phoneNumberLength < 5) return phoneNumber.slice(1);
    if (phoneNumberLength < 8) return `(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4)}`;
    return `(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
  } else {
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
};

const EditableEmployeeTable = ({
  employees,
  setEmployees,
  newEmployees,
  setNewEmployees,
  removeEmployee,
  handleEmployeeChange,
}) => {
  const [expandedUser, setExpandedUser] = useState(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const currentUserPhoneNumber = useSelector((state) => state.user.user?.phone);
  const detailsRefs = useRef({});

  useEffect(() => {
    // Initialize tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

    // Expand the first employee on initial render for mobile devices
    if (isInitialRender && window.innerWidth <= 767 && employees.length > 0) {
      setExpandedUser(employees[0].phone_number);
      if (detailsRefs.current[employees[0].phone_number]) {
        detailsRefs.current[employees[0].phone_number].style.maxHeight = detailsRefs.current[employees[0].phone_number].scrollHeight + "px";
      }
    }
  }, [isInitialRender, employees]);

  const handleNewEmployeeChange = (index, field, value) => {
    const updatedNewEmployees = [...newEmployees];
    if (field === 'phone_number') {
      updatedNewEmployees[index][field] = formatPhoneNumber(value);
    } else {
      updatedNewEmployees[index][field] = value;
    }
    setNewEmployees(updatedNewEmployees);
  };

  const removeNewEmployee = (index) => {
    const updatedNewEmployees = [...newEmployees];
    updatedNewEmployees.splice(index, 1);
    setNewEmployees(updatedNewEmployees);
  };

  const toggleExpand = (phoneNumber) => {
    setExpandedUser(prevExpanded => {
      if (prevExpanded === phoneNumber) {
        // Closing
        if (detailsRefs.current[phoneNumber]) {
          detailsRefs.current[phoneNumber].style.maxHeight = null;
        }
        return null;
      } else {
        // Opening
        if (detailsRefs.current[phoneNumber]) {
          detailsRefs.current[phoneNumber].style.maxHeight = detailsRefs.current[phoneNumber].scrollHeight + "px";
        }
        // Close previously expanded card
        if (prevExpanded && detailsRefs.current[prevExpanded]) {
          detailsRefs.current[prevExpanded].style.maxHeight = null;
        }
        return phoneNumber;
      }
    });
    setIsInitialRender(false);
  };

  const estimators = employees.filter(e => e.estimator);

  return (
    <>
      <table className="table desktop-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Language</th>
            <th>
              Estimator <span 
                className="tooltip-icon"
                data-bs-toggle="tooltip" 
                data-bs-placement="top" 
                title="An estimator is responsible for pricing and completing change orders."
              ></span>
            </th>
            <th>
              Assigned Estimator <span 
                className="tooltip-icon"
                data-bs-toggle="tooltip" 
                data-bs-placement="top" 
                title="Change orders get sent to this person for pricing and completion."
              ></span>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee) => (
            <tr key={employee.phone_number}>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={employee.first_name}
                  onChange={(e) => handleEmployeeChange(employee.phone_number, 'first_name', e.target.value)}
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={employee.last_name}
                  onChange={(e) => handleEmployeeChange(employee.phone_number, 'last_name', e.target.value)}
                  required
                />
              </td>
              <td>
                <span>{formatPhoneNumber(employee.phone_number)}</span>
              </td>
              <td>
                <select
                  className="form-control"
                  value={employee.language}
                  onChange={(e) => handleEmployeeChange(employee.phone_number, 'language', e.target.value)}
                >
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                </select>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={employee.estimator}
                  onChange={(e) => handleEmployeeChange(employee.phone_number, 'estimator', e.target.checked)}
                />
              </td>
              <td>
                <select
                  className="form-control"
                  value={employee.last_co_estimator || ''}
                  onChange={(e) => handleEmployeeChange(employee.phone_number, 'last_co_estimator', e.target.value)}
                  disabled={employee.estimator}
                >
                  <option value="">Not Assigned</option>
                  {estimators.map((estimator) => (
                    <option key={estimator.phone_number} value={estimator.phone_number}>
                      {estimator.first_name} {estimator.last_name}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                {employee.phone_number !== currentUserPhoneNumber && (
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => removeEmployee(employee.phone_number)}
                  >
                    Remove
                  </button>
                )}
              </td>
            </tr>
          ))}
          {newEmployees.map((employee, index) => (
            <tr key={`new-${index}`}>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={employee.first_name}
                  onChange={(e) => handleNewEmployeeChange(index, 'first_name', e.target.value)}
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  value={employee.last_name}
                  onChange={(e) => handleNewEmployeeChange(index, 'last_name', e.target.value)}
                  required
                />
              </td>
              <td>
                <input
                  type="tel"
                  className="form-control"
                  value={formatPhoneNumber(employee.phone_number)}
                  onChange={(e) => handleNewEmployeeChange(index, 'phone_number', e.target.value)}
                  required
                />
              </td>
              <td>
                <select
                  className="form-control"
                  value={employee.language}
                  onChange={(e) => handleNewEmployeeChange(index, 'language', e.target.value)}
                >
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                </select>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={employee.estimator}
                  onChange={(e) => handleNewEmployeeChange(index, 'estimator', e.target.checked)}
                />
              </td>
              <td>
                <select
                  className="form-control"
                  value={employee.last_co_estimator || ''}
                  onChange={(e) => handleNewEmployeeChange(index, 'last_co_estimator', e.target.value)}
                  disabled={employee.estimator}
                >
                  <option value="">Select Estimator</option>
                  {estimators.map((estimator) => (
                    <option key={estimator.phone_number} value={estimator.phone_number}>
                      {estimator.first_name} {estimator.last_name}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <button className="btn btn-danger btn-sm" onClick={() => removeNewEmployee(index)}>
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="employee-table mobile-table">
        {employees.map((employee) => (
          <div
            key={employee.phone_number}
            className={`employee-card ${expandedUser === employee.phone_number ? 'expanded' : ''}`}
          >
            <div className="employee-info" onClick={() => toggleExpand(employee.phone_number)}>
              <div>{employee.first_name} {employee.last_name}</div>
              <div>{formatPhoneNumber(employee.phone_number)}</div>
            </div>
            <div 
              className="employee-details"
              ref={el => detailsRefs.current[employee.phone_number] = el}
            >
              <div>
                <label>First Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={employee.first_name}
                  onChange={(e) => handleEmployeeChange(employee.phone_number, 'first_name', e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Last Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={employee.last_name}
                  onChange={(e) => handleEmployeeChange(employee.phone_number, 'last_name', e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Language:</label>
                <select
                  className="form-control"
                  value={employee.language}
                  onChange={(e) => handleEmployeeChange(employee.phone_number, 'language', e.target.value)}
                >
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                </select>
              </div>
              <div>
                <label>Estimator:</label>
                <input
                  type="checkbox"
                  checked={employee.estimator}
                  onChange={(e) => handleEmployeeChange(employee.phone_number, 'estimator', e.target.checked)}
                />
              </div>
              <div>
                <label>Assigned Estimator:</label>
                <select
                  className="form-control"
                  value={employee.last_co_estimator || ''}
                  onChange={(e) => handleEmployeeChange(employee.phone_number, 'last_co_estimator', e.target.value)}
                  disabled={employee.estimator}
                >
                  <option value="">Not Assigned</option>
                  {estimators.map((estimator) => (
                    <option key={estimator.phone_number} value={estimator.phone_number}>
                      {estimator.first_name} {estimator.last_name}
                    </option>
                  ))}
                </select>
              </div>
              {employee.phone_number !== currentUserPhoneNumber && (
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => removeEmployee(employee.phone_number)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        ))}
        {newEmployees.map((employee, index) => (
          <div
            key={`new-${index}`}
            className={`employee-card new-employee ${expandedUser === `new-${index}` ? 'expanded' : ''}`}
          >
            <div className="employee-info" onClick={() => toggleExpand(`new-${index}`)}>
              <div>New Employee</div>
            </div>
            <div 
              className="employee-details"
              ref={el => detailsRefs.current[`new-${index}`] = el}
            >
              <div>
                <label>First Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={employee.first_name}
                  onChange={(e) => handleNewEmployeeChange(index, 'first_name', e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Last Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={employee.last_name}
                  onChange={(e) => handleNewEmployeeChange(index, 'last_name', e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Phone Number:</label>
                <input
                  type="tel"
                  className="form-control"
                  value={formatPhoneNumber(employee.phone_number)}
                  onChange={(e) => handleNewEmployeeChange(index, 'phone_number', e.target.value)}
                  required
                />
              </div>
              <div>
                <label>Language:</label>
                <select
                  className="form-control"
                  value={employee.language}
                  onChange={(e) => handleNewEmployeeChange(index, 'language', e.target.value)}
                >
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                </select>
              </div>
              <div>
                <label>Estimator:</label>
                <input
                  type="checkbox"
                  checked={employee.estimator}
                  onChange={(e) => handleNewEmployeeChange(index, 'estimator', e.target.checked)}
                />
              </div>
              <div>
                <label>Assigned Estimator:</label>
                <select
                  className="form-control"
                  value={employee.last_co_estimator || ''}
                  onChange={(e) => handleNewEmployeeChange(index, 'last_co_estimator', e.target.value)}
                  disabled={employee.estimator}
                >
                  <option value="">Select Estimator</option>
                  {estimators.map((estimator) => (
                    <option key={estimator.phone_number} value={estimator.phone_number}>
                      {estimator.first_name} {estimator.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <button className="btn btn-danger btn-sm" onClick={() => removeNewEmployee(index)}>
                Remove
              </button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default EditableEmployeeTable;