import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaTachometerAlt, FaBriefcase, FaClipboard, FaBars, FaComments, FaUsers, FaCreditCard, FaSignOutAlt, FaPuzzlePiece } from 'react-icons/fa';
import { userLoggedOut } from '../redux/actions/userActions';
import { authApi } from '../constants/axiosConfig';
import Cookies from 'js-cookie';
import './Sidebar.css';
import logo from '../assets/images/Logo120x120.png';

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getNavLinkClass = ({ isActive }) => {
    return isActive ? 'nav-item active' : 'nav-item';
  };

  const renderNavLink = (to, IconComponent, label) => {
    return (
      <NavLink to={to} className={getNavLinkClass} onClick={() => isMobile && setIsSidebarOpen(false)}>
        <IconComponent />
        <span>{label}</span>
      </NavLink>
    );
  };

  const handleLogout = async () => {
    try {
      const sessionToken = Cookies.get('sessionToken');

      if (sessionToken) {
        await authApi.post('/logout', { session_token: sessionToken });
      }

      Cookies.remove('sessionToken');
      Cookies.remove('phoneNumber');

      dispatch(userLoggedOut());
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      dispatch(userLoggedOut());
      navigate('/');
    }
  };

  return (
    <div className="sidebar-component">
      {isMobile && (
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          <FaBars />
        </button>
      )}
      <div className={`sidebar ${isSidebarOpen ? 'open' : 'collapsed'} ${isMobile ? 'mobile' : ''}`}>
        <div className="sidebar-header">
          <div className="sidebar-logo-container">
            <img src={logo} alt="Logo" className="sidebar-logo" />
            <div className="sidebar-text-container">
              <span className="sidebar-company-name">Bridgepoint AI</span>
              <span className="sidebar-assistant-name">Change Order Assistant</span>
            </div>
          </div>
        </div>
        {user && (
          <div className="sidebar-profile">
            <div className="sidebar-profile-name">{user.name}</div>
            <button className="sidebar-logout-btn" onClick={handleLogout}>
              <FaSignOutAlt />
              <span>Logout</span>
            </button>
          </div>
        )}
        <nav className="sidebar-nav">
          {renderNavLink("/assistant", FaComments, "Assistant")}
          {renderNavLink("/dashboard", FaTachometerAlt, "Dashboard")}
          {renderNavLink("/jobs", FaBriefcase, "Jobs")}
          {renderNavLink("/users", FaUsers, "Users")}
          {renderNavLink("/company", FaClipboard, "Company")}
          {renderNavLink("/billing", FaCreditCard, "Billing")}
          {renderNavLink("/integrations", FaPuzzlePiece, "Integrations")}
        </nav>
        {!isMobile && (
          <button className="sidebar-toggle" onClick={toggleSidebar}>
            <FaBars />
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar;