// Dashboard/DashboardTable.js
import React from 'react';
import ChangeOrdersTable from '../shared/ChangeOrdersTable/ChangeOrdersTable';
import './DashboardTable.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const DashboardTable = ({ region, status, changeOrders }) => {
  const filteredChangeOrders = changeOrders.filter(order => order.status === status);

  if (filteredChangeOrders.length === 0) {
    return null;  // Don't render anything if there are no change orders for this status
  }

  return (
    <div className="dashboard-table">
      <div className="dashboard-table__container mb-4">
        <ChangeOrdersTable
          changeOrders={filteredChangeOrders}
          status={status}
        />
      </div>
    </div>
  );
};

export default DashboardTable;