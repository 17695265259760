// ChangeOrderComponents/ScheduleExtension/ScheduleExtension.js
import React from 'react';
import { useSelector } from 'react-redux';
import './ScheduleExtension.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const ScheduleExtension = () => {
  const { changeOrderDetails } = useSelector((state) => state.changeOrder);
  const { schedule_extension } = changeOrderDetails.bigDict;

  if (!schedule_extension) return null;

  const formatExtensionTime = (extension) => {
    const parts = [];
    if (extension.Months) parts.push(`${extension.Months} Month${extension.Months > 1 ? 's' : ''}`);
    if (extension.Weeks) parts.push(`${extension.Weeks} Week${extension.Weeks > 1 ? 's' : ''}`);
    if (extension.Days) parts.push(`${extension.Days} Day${extension.Days > 1 ? 's' : ''}`);
    return parts.join(', ');
  };

  return (
    <div className="schedule-extension-component mb-4">
      <h3>Schedule Extension Needed:</h3>
      <p>{formatExtensionTime(schedule_extension)}</p>
    </div>
  );
};

export default ScheduleExtension;