import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import API_BASE_URL from '../../constants/apiConfig';
import ChangeOrdersTable from '../shared/ChangeOrdersTable/ChangeOrdersTable';
import EditButton from '../shared/EditButton';
import './JobView.css';

const JobValues = ({ jobData }) => (
  <div className="job-values-container bg-light p-4 rounded-3 shadow-sm">
    <div className="job-values-group mb-3 justify-content-between">
      <div className="job-values-item">
        <span className="job-values-label">Job Name:&nbsp;</span>
        <span>{jobData.jobName}</span>
      </div>
      <div className="job-values-item">
        <span className="job-values-label">General Contractor:&nbsp;</span>
        <span>{jobData.generalContractor}</span>
      </div>
      <div className="job-values-item">
        <span className="job-values-label">Job Status:&nbsp;</span>
        <span>{jobData.status}</span>
      </div>
    </div>
    <div>
      <h5 className="fw-normal mb-2">Emails receiving weekly COR reports:</h5>
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {jobData.emails && jobData.emails.length > 0 ? (
              jobData.emails.map((email, index) => (
                <tr key={index}>
                  <td>{email}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No current emails</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

const EditableJobValues = ({ editedJobData, setEditedJobData }) => {
  const statuses = ['Active', 'Inactive'];
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (index, value) => {
    const updatedEmails = editedJobData.emails.map((email, i) => i === index ? value : email);
    setEditedJobData({ ...editedJobData, emails: updatedEmails });
  };

  const handleAddEmail = () => {
    setEditedJobData({ ...editedJobData, emails: [...editedJobData.emails, ''] });
  };

  const handleRemoveEmail = (index) => {
    const filteredEmails = editedJobData.emails.filter((_, i) => i !== index);
    setEditedJobData({ ...editedJobData, emails: filteredEmails });
  };

  return (
    <form className="job-values-container bg-light p-4 rounded-3 shadow-sm">
      <div className="d-flex mb-3 align-items-center">
        <label htmlFor="jobName" className="form-label job-values-label">Job Name:</label>
        <input
          type="text"
          className="form-control job-values-input"
          id="jobName"
          name="jobName"
          value={editedJobData.jobName}
          onChange={(e) => setEditedJobData({ ...editedJobData, jobName: e.target.value })}
        />
      </div>
      <div className="d-flex mb-3 align-items-center">
        <label htmlFor="generalContractor" className="form-label job-values-label">General Contractor:</label>
        <input
          type="text"
          className="form-control job-values-input"
          id="generalContractor"
          name="generalContractor"
          value={editedJobData.generalContractor}
          onChange={(e) => setEditedJobData({ ...editedJobData, generalContractor: e.target.value })}
        />
      </div>
      <div className="d-flex mb-3 align-items-center">
        <label htmlFor="jobStatus" className="form-label job-values-label">Status:</label>
        <select
          id="jobStatus"
          className="form-select job-values-input"
          value={editedJobData.status}
          onChange={(e) => setEditedJobData({ ...editedJobData, status: e.target.value })}
        >
          {statuses.map((status, index) => (
            <option key={index} value={status}>{status}</option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label className="form-label job-values-label">Emails receiving weekly COR reports:</label>
        {editedJobData.emails.map((email, index) => (
          <div key={index} className="d-flex mb-2 align-items-center">
            <input
              type="email"
              className={`form-control job-values-input ${!emailRegex.test(email) ? 'is-invalid' : ''}`}
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
            />
            <button type="button" className="btn btn-danger ms-2" onClick={() => handleRemoveEmail(index)}>Remove</button>
            {!emailRegex.test(email) && <div className="invalid-feedback">Invalid email address</div>}
          </div>
        ))}
        <div className="btn-group">
          <button type="button" className="btn btn-secondary" onClick={handleAddEmail}>Add Email</button>
        </div>
      </div>
    </form>
  );
};

const JobView = ({ isNewJob = false }) => {
  const navigate = useNavigate();
  const { jobName } = useParams();
  const user = useSelector((state) => state.user.user);
  
  const [jobData, setJobData] = useState(null);
  const [editedJobData, setEditedJobData] = useState({
    id: '',
    jobName: '',
    generalContractor: '',
    status: '',
    emails: [],
  });
  const [isEditable, setIsEditable] = useState(isNewJob);
  const [changeOrders, setChangeOrders] = useState([]);
  const [errors, setErrors] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const formatJobName = (name) => name.trim().replace(/-+/g, ' ');
  const formattedJobName = jobName ? formatJobName(jobName) : '';

  const fetchJobDetails = useCallback(async () => {
    if (user?.customer_id && formattedJobName) {
      try {
        const response = await axios.get(`${API_BASE_URL}/job`, {
          params: { job_name: formattedJobName, customer_id: user.customer_id },
        });
        setJobData(response.data);
        setErrors((prev) => ({ ...prev, jobDetails: null }));
      } catch (error) {
        console.error('Error fetching job details:', error);
        setErrors((prev) => ({ ...prev, jobDetails: error }));
      }
    }
  }, [user, formattedJobName]);

  const fetchChangeOrders = useCallback(async () => {
    if (user?.customer_id && formattedJobName) {
      try {
        const params = {
          customer_id: user.customer_id,
          job_name: formattedJobName,
        };
        const response = await axios.get(`${API_BASE_URL}/job/change-orders`, { params });
        if (response.data.success) {
          setChangeOrders(response.data.data);
          setErrors((prev) => ({ ...prev, changeOrders: null }));
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        if (error.response?.status === 404) {
          setChangeOrders([]);
          setErrors((prev) => ({ ...prev, changeOrders: null }));
        } else {
          setErrors((prev) => ({ ...prev, changeOrders: error }));
        }
      }
    }
  }, [user, formattedJobName]);  

  useEffect(() => {
    if (!isNewJob) {
      fetchJobDetails();
      fetchChangeOrders();
    }
  }, [isNewJob, fetchJobDetails, fetchChangeOrders]);

  const handleEnterEditMode = () => {
    setEditedJobData({
      id: jobData?.id || '',
      jobName: jobData?.jobName || '',
      generalContractor: jobData?.generalContractor || '',
      status: jobData?.status || '',
      emails: jobData?.emails || [],
    });
    setIsEditable(true);
  };

  const handleCancelEditMode = () => {
    setEditedJobData({
      id: '',
      jobName: '',
      generalContractor: '',
      status: '',
      emails: [],
    });
    setIsEditable(false);
    if (isNewJob) navigate('/jobs');
  };

  const handleSaveEditMode = async () => {
    const trimData = (data) => ({
      ...data,
      jobName: data.jobName.trim(),
      generalContractor: data.generalContractor.trim(),
      emails: data.emails.map(email => email.trim()),
    });

    const trimmedData = trimData(editedJobData);
    const formattedJobName = formatJobName(trimmedData.jobName);

    setIsUpdating(true);
    try {
      const endpoint = isNewJob ? '/job/create' : '/job/update';
      await axios.post(`${API_BASE_URL}${endpoint}`, {
        ...trimmedData,
        customer_id: user.customer_id,
      });
      setIsUpdating(false);
      setErrors((prev) => ({ ...prev, update: null }));
      if (isNewJob) {
        setIsEditable(false);
        navigate(`/job/${formattedJobName}`);
      } else {
        setIsEditable(false);
        setJobData(trimmedData);
      }
    } catch (error) {
      console.error('Error updating job details:', error);
      setErrors((prev) => ({ ...prev, update: error }));
      setIsUpdating(false);
    }
  };

  const groupChangeOrdersByStatus = (changeOrdersList) => {
    return changeOrdersList.reduce((acc, order) => {
      const { status } = order;
      if (!acc[status]) acc[status] = [];
      acc[status].push(order);
      return acc;
    }, {});
  };

  const groupedChangeOrders = groupChangeOrdersByStatus(changeOrders);
  const statusOrder = ['Incomplete', 'Unexecuted', 'Executed', 'Cancelled'];
  const sortedGroupedChangeOrders = statusOrder
    .filter((status) => groupedChangeOrders[status])
    .map((status) => [status, groupedChangeOrders[status]]);

  if (errors.jobDetails) {
    return <div className="alert alert-danger" role="alert">Error loading job details: {errors.jobDetails.message}</div>;
  }

  if (errors.update) {
    return <div className="alert alert-danger" role="alert">Error updating job details: {errors.update.message}</div>;
  }

  if (isUpdating) {
    return <div>Updating job details...</div>;
  }

  if (!isNewJob && !jobData && !isUpdating) {
    return <div>Loading job details...</div>;
  }

  return (
    <div className="container mt-5 jobview-container job-view">
      <div className="card shadow">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h2 className="card-title">Job Details</h2>
            <EditButton
              isEditable={isEditable}
              onEdit={handleEnterEditMode}
              onSave={handleSaveEditMode}
              onCancel={handleCancelEditMode}
            />
          </div>

          {isEditable ? (
            <EditableJobValues
              editedJobData={editedJobData}
              setEditedJobData={setEditedJobData}
            />
          ) : jobData && !isNewJob ? (
            <JobValues jobData={jobData} />
          ) : (
            <div>Please fill in the job details.</div>
          )}

          {!isEditable && jobData && !isNewJob && (
            <>
              <h3 className="mt-4">Change Order Requests</h3>
              {errors.changeOrders && errors.changeOrders.response?.status !== 404 && (
                <div className="alert alert-warning" role="alert">
                  Error fetching change orders: {errors.changeOrders.message}
                </div>
              )}
              {sortedGroupedChangeOrders.length > 0 ? (
                sortedGroupedChangeOrders.map(([status, orders]) => (
                  <div key={status} className="job-view-change-order-table">
                    <ChangeOrdersTable
                      changeOrders={orders}
                      status={status}
                      excludeJobNames={true}
                    />
                  </div>
                ))
              ) : (
                <div>No change orders to display for this job.</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobView;