import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from './SignUpService';
import './LandingPage.css';
import { IoIosArrowDown } from 'react-icons/io';
import phoneImage from '../../assets/images/Chat-Change-Order.png';
import companyLogo from '../../assets/images/TopSectionLogo.png';
import phoneimage2 from '../../assets/images/Chat-Material-Price.png';
import phoneimage3 from '../../assets/images/Assistant-Dashboard-Image.png';
import customer1Image from '../../assets/images/Zitting-Companies-Logo.png';
import customer2Image from '../../assets/images/ASM-Comany-Logo.png';
import customer3Image from '../../assets/images/Zitting-Window&Door-Logo.png';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { userLoggedIn, userLoggedOut } from '../../redux/actions/userActions';
import { authApi } from '../../constants/axiosConfig';

const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showTopSection, setShowTopSection] = useState(false);
  const [isLearnMoreAnimated, setIsLearnMoreAnimated] = useState(false);
  const [isArrowAnimated, setIsArrowAnimated] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const [formState, setFormState] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    yourPhoneNumber: '',
  });

  const [message, setMessage] = useState('');

  const clearSessionAndLogout = useCallback(() => {
    Cookies.remove('sessionToken');
    Cookies.remove('phoneNumber');
    dispatch(userLoggedOut());
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLearnMoreAnimated(true);
      setIsArrowAnimated(true);
    }, 100);
  
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      document.documentElement.style.setProperty('--scroll-y', `${scrollY}px`);
      
      if (window.innerWidth > 768) {
        setShowTopSection(scrollY > 500);
      } else {
        setShowTopSection(false);
      }
    };
  
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'yourPhoneNumber') {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setFormState((prevState) => ({
        ...prevState,
        [name]: formattedPhoneNumber,
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formState.companyName || !formState.firstName || !formState.lastName || !formState.yourPhoneNumber) {
      setMessage('Please fill in all fields.');
      return;
    }

    try {
      const response = await signUp(formState);

      if (response.status === 200) {
        setMessage('Sign up successful. Change Order Assistant will be in touch shortly!');
        setFormState({
          companyName: '',
          firstName: '',
          lastName: '',
          yourPhoneNumber: '',
        });
      } else {
        setMessage('Sign up failed. Please try again later.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.');
    }
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleScrollAnimation = (targetId) => {
    if (!isScrolling) {
      setIsScrolling(true);
      setTimeout(() => {
        document.getElementById(targetId).scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => setIsScrolling(false), 1000);
      }, 100);
    }
  };

  const handleLearnMoreClick = () => {
    if (!isScrolling) handleScrollAnimation('about');
  };
  
  const handleArrowClick = () => {
    if (!isScrolling) handleScrollAnimation('about');
  };

  const handleLoginClick = async () => {
    const sessionToken = Cookies.get('sessionToken');
    const phoneNumber = Cookies.get('phoneNumber');
    
    if (sessionToken && phoneNumber) {
      try {
        const response = await authApi.post('/verify-session', {
          phone: phoneNumber,
          session_token: sessionToken
        });
        if (response.data.success) {
          dispatch(userLoggedIn(response.data.user));
          navigate('/dashboard');
        } else {
          throw new Error('Session verification failed');
        }
      } catch (error) {
        console.error('Session verification error', error);
        if (error.response) {
          switch (error.response.status) {
            case 401:
              setMessage('Your session has expired. Please log in again.');
              break;
            default:
              setMessage('An error occurred. Please try again later.');
          }
        } else {
          setMessage('Network error. Please check your connection and try again.');
        }
        clearSessionAndLogout();
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="landing-page-root">
      <div className="page-container">
        <div className={`top-section ${showTopSection ? 'visible' : ''}`}>
          <img src={companyLogo} alt="Company Logo" className="logo" />
          <span className="company-name">BridgePoint AI</span>
          <div className="login-button-container">
            <button className="btn btn-lg btn-primary" onClick={handleLoginClick}>Log In</button>
          </div>
        </div>
        <div className="landing-page">
          <section id="landing" className="banner-section">
            <div className="container text-center">
              <h1 className="display-3 fw-bold">
                Meet Change Order Assistant
                <span className="line-break"></span>
                <span className="text"></span>
              </h1>
              <p className="lead">Your tool for creating and tracking change order requests.</p>
              <div className="fade-line"></div>
              <div className="mt-4">
                <button className="btn btn-lg btn-primary me-3" onClick={handleLoginClick}>Log In</button>
                <button 
                  className={`btn btn-lg btn-outline-secondary ${isLearnMoreAnimated ? 'btn-bounce' : ''}`} 
                  onClick={handleLearnMoreClick}
                >
                  Learn More
                </button>
              </div>
            </div>
            <div 
              className={`scroll-arrow ${isArrowAnimated ? 'btn-bounce' : ''}`} 
              onClick={handleArrowClick}
            >
              <IoIosArrowDown />
            </div>
          </section>
  
          <section id="about" className="about-section">
            <div className="container">
              <h2>About Change Order Assistant</h2>
              <p>Change Order Assistant is a tool for subcontractors to create and track change order requests. It makes the process of managing change orders simple and effective.</p>
              
              <div className="trust-statement">
                <h3>Trusted by Companies Like You</h3>
                
                <div className="customer-reviews">
                  <div className="review">
                    <img src={customer1Image} alt="Customer 1 Logo" />
                  </div>
                  <div className="review">
                    <img src={customer2Image} alt="Customer 2 Logo" />
                  </div>
                  <div className="review">
                    <img src={customer3Image} alt="Customer 3 Logo" />
                  </div>
                </div>
              </div>
            </div>
            <div className="scroll-arrow-container">
            </div>
            <div 
              className={`scroll-arrow blue ${isArrowAnimated ? 'btn-bounce' : ''}`} 
              onClick={() => handleScrollAnimation('phone-section')}
            >
              <IoIosArrowDown />
            </div>
          </section>
  
          <section id="phone-section" className="phone-section">
            <div className="image-content top-left">
              <img src={phoneImage} alt="Change Order Assistant Mobile Interface" className="phone-image" />
            </div>
            <div className="diagonal-line"></div>
            <div className="diagonal-line"></div>
            <div className="diagonal-line"></div>
            
            <div className="text-content">
              <h2>Stay Connected On The Go</h2>
              <p>Access Change Order Assistant from your mobile device anytime, anywhere.</p>
            </div>
            <div className="image-content bottom-right">
              <img src={phoneimage2} alt="Change Order Assistant Features" className="phone-image" />
            </div>
            <div className="bottom-center-container">
              <div className="text-content bottom-text">
                <p>Manage your change order requests with ease from the Change Order Dashboard.</p>
              </div>
              <div className="image-content bottom-center">
                <img src={phoneimage3} alt="Additional Change Order Assistant Feature" className="phone-image" />
              </div>
            </div>
          </section>
  
          <section id="signup" className="signup-section">
            <div className="container">
              <h2>Try Assistant Yourself</h2>
              <form onSubmit={handleSubmit} className="signup-form">
                <input type="text" id="companyName" name="companyName" placeholder="Company Name" value={formState.companyName} onChange={handleInputChange} />
                <input type="text" id="firstName" name="firstName" placeholder="Your First Name" value={formState.firstName} onChange={handleInputChange} />
                <input type="text" id="lastName" name="lastName" placeholder="Your Last Name" value={formState.lastName} onChange={handleInputChange} />
                <input type="tel" id="yourPhoneNumber" name="yourPhoneNumber" placeholder="Your Phone Number" value={formState.yourPhoneNumber} onChange={handleInputChange} />
                <div id="message" className="form-message">{message}</div>
                <button type="submit" className="btn btn-lg btn-primary">Have Assistant Text Me</button>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;