import React, { useEffect, useState } from 'react';
import { Tooltip } from 'bootstrap';
import './UsersPage.css';

const formatPhoneNumber = (value) => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumber.startsWith('1')) {
    if (phoneNumberLength < 5) return phoneNumber.slice(1);
    if (phoneNumberLength < 8) return `(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4)}`;
    return `(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
  } else {
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
};

const EmployeeTable = ({ employees }) => {
  const [expandedUser, setExpandedUser] = useState(null);
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    // Initialize tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

    // Expand the first user on initial render for mobile devices
    if (isInitialRender && window.innerWidth <= 767 && employees.length > 0) {
      setExpandedUser(employees[0].phone_number);
    }
  }, [isInitialRender, employees]);

  if (!Array.isArray(employees)) {
    console.error('employees is not an array:', employees);
    return <div>Error: Unable to display employees</div>;
  }

  const estimators = employees.filter(e => e.estimator);

  const getAssignedEstimator = (employee) => {
    if (employee.estimator) return 'N/A';
    if (!employee.last_co_estimator) return 'Not assigned';
    const assignedEstimator = estimators.find(e => e.phone_number === employee.last_co_estimator);
    return assignedEstimator 
      ? `${assignedEstimator.first_name} ${assignedEstimator.last_name}`
      : 'Not assigned';
  };

  const toggleExpand = (phoneNumber) => {
    setExpandedUser(expandedUser === phoneNumber ? null : phoneNumber);
    setIsInitialRender(false);
  };

  return (
    <>
      <table className="table desktop-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Language</th>
            <th>
              Estimator <span 
                className="tooltip-icon"
                data-bs-toggle="tooltip" 
                data-bs-placement="top" 
                title="An estimator is responsible for pricing and completing change orders."
              ></span>
            </th>
            <th>
              Assigned Estimator <span 
                className="tooltip-icon"
                data-bs-toggle="tooltip" 
                data-bs-placement="top" 
                title="Change orders get sent to this person for pricing and completion."
              ></span>
            </th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee) => (
            <tr key={employee.phone_number}>
              <td>{employee.first_name}</td>
              <td>{employee.last_name}</td>
              <td>{formatPhoneNumber(employee.phone_number)}</td>
              <td>{employee.language}</td>
              <td>{employee.estimator ? 'Yes' : 'No'}</td>
              <td>{getAssignedEstimator(employee)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="employee-table mobile-table">
        {employees.map((employee) => (
          <div 
            key={employee.phone_number} 
            className={`employee-card ${expandedUser === employee.phone_number ? 'expanded' : ''}`}
          >
            <div className="employee-info" onClick={() => toggleExpand(employee.phone_number)}>
              <div>{employee.first_name} {employee.last_name}</div>
              <div>{formatPhoneNumber(employee.phone_number)}</div>
            </div>
            <div className="employee-details">
              <div>Language: {employee.language}</div>
              <div>Estimator: {employee.estimator ? 'Yes' : 'No'}</div>
              <div>Assigned Estimator: {getAssignedEstimator(employee)}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default EmployeeTable;